<template>
  <div>
    <!--無權限-->

    <!--有權限-->
    <v-switch
      inset
      dense
      color="green"
      @mouseup="onClick"
      :disabled="disabled"
      :true-value="values.true"
      :false-value="values.false"
      :value="values"
      v-model="value"
    >
      <template v-slot:message="{ message }">
        <div class="text-truncate orange--text">{{message}}</div>
      </template>
    </v-switch>
  </div>
</template>

<script>
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  data: () => ({
    confirmLock: false,
    value: null,
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      this.value = window.eagleLodash.cloneDeep(this.itemData)
    },
    onClick() {
      if(this.confirmLock) return
      this.confirmLock = true
      this.$apopup.base({
        title: this.$t('update.confirm'),
        content: this.confirmContent,
        applyCallback: async () => await this.patch(this.value),
        closeCallback: (isCancel) => {
          this.confirmLock = false
          if(isCancel) this.init()
        },
      })
    },
  },
  computed: {
    confirmContent() {
      if(typeof this.config.confirmContent != 'function') return null
      return this.config.confirmContent(this.row, this.value)
    },
    defaultValues() {
      return {
        true: true,
        false: false,
      }
    },
    values() {
      let result = window.eagleLodash.cloneDeep(this.defaultValues)
      if(!this.config.values) return result
      if(this.config.values.true != undefined) result.true = this.config.values.true
      if(this.config.values.false != undefined) result.false = this.config.values.false
      return result
    },
  },
  watch: {
    itemData() {
      this.init()
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
